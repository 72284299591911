import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout/Layout';
import Card from '../components/Card';
import SplitSection from '../components/SplitSection';
import Callout from '../components/Callout';
import CalloutSimple from '../components/CalloutSimple';
import Seo from '../components/Seo';
import ArrowDown from '../components/ArrowDown';

import tick from '../assets/icons/tick.png'



export default () => {
  const title = 'Legal Information | Strio ';
  const description =
    "Strio's legal information";
  const url = 'https://strio.app/legal-info';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <div className="bg-lownight md:h-20 h-16" />
      <section className="flex justify-center relative pa-0 overflow-hidden mb-20">
        <div className="w-1/2">
              <h1 className="pb-4 lg:mt-10 text-2xl lg:text-3xl xl:text-4xl font-bold leading-none tracking-tight text-center">
                Legal Information
              </h1>
        <div className="text-left">
          <h3 className="text-lownight text-2xl my-4">
            Legal Form
          </h3>
          <p className="text-gray-700">
            Limited Liability Company - Société A Responsabilité Limitée (SARL)
          </p>
          <h3 className="text-lownight text-2xl my-4">
            Trade and Companies Register
          </h3>
          <p className="text-gray-700">
            RCS Paris 502 178 429
          </p>
          <h3 className="text-lownight text-2xl my-4">
            Publication Director
          </h3>
          <p className="text-gray-700">
            François BALIGANT
          </p>
          <h3 className="text-lownight text-2xl my-4">
            Registered Location
          </h3>
          <p className="text-gray-700">
          103 Rue Réaumur 75002 Paris
          </p>
        </div>
      </div>
      </section>
    </Layout>
  );
};
